import { z } from "../libraries/zod.js"
import { 
    getWishlistFromCookie, 
    removeAllFromWishlist, 
    wishlistChangeFetch, 
    getBasketFromCookie, 
    basketChangeFetch, 
    removeAllFromBasket,
    userLoggedIn
} from "./cookies.js"

function getTextInsideSquareBrackets(text) {
    const startIndex = text.indexOf('['); // Находим индекс первой открывающей скобки
    const endIndex = text.lastIndexOf(']'); // Находим индекс последней закрывающей скобки
    if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) { // Проверяем, что скобки найдены и первая скобка находится перед последней
        return text.substring(startIndex + 1, endIndex); // Возвращаем текст между первой и последней скобками
    } else {
        return ''; // Если скобки не найдены или первая скобка находится после последней, возвращаем пустую строку
    }
}

const schemaRegister = z.object({
    email: z.string()
        .email({ message: "Invalid email address" }),
    password: z.string()
        .min(4,{message: "слишком коротко"}),
    repeat_password: z.string()
        .min(4,{message: "слишком коротко"}),
}).refine(
    (data) => data.password === data.repeat_password, {
    message: "Passwords don't match",
    path: ["password","repeat_password"], // path of error
});

const schemaNewPass = z.object({
    password: z.string()
        .min(4,{message: "слишком коротко"}),
    repeat_password: z.string()
        .min(4,{message: "слишком коротко"}),
}).refine(
    (data) => data.password === data.repeat_password, {
    message: "Passwords don't match",
    path: ["password","repeat_password"], // path of error
});

const schemaLogin = z.object({
    email: z.string()
        .email({ message: "Invalid email address" }),
    password: z.string()
        .min(4,{message: "слишком коротко"}),
});

const schemaVerification = z.object({
    code: z.string()
        .min(4)
        .max(4)
});

const schemaRecovery = z.object({
    email: z.string()
        .email({ message: "Invalid email address" }),
});

const schemaCallBack = z.object({
    email: z.string(),
    phone: z.string()
        .min(18),
    first_name: z.string()
        .min(1),
    last_name: z.string()
        .min(1),
});

const schemaReview = z.object({
    first_name: z.string()
        .min(1),
    last_name: z.string()
        .min(1),
    review: z.string()
        .min(1),
});

const schemaUpdUserInfo = z.object({
    email: z.string()
        .email({ message: "Invalid email address" }),
    phone: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    address: z.string(),
});

const schemaOrder = z.object({
    first_name: z.string()
        .min(1),
    last_name: z.string()
        .min(1),
    phone: z.string()
        .min(18),
    address: z.string()
        .min(3),
    email: z.string()
});




export function validation() {

    const register = document.getElementById('registration');
    const registerId = '#registration';
    const registerSendOn = '/authorise/registration/verification/';
    if(register) {
        formValidate(
            register, 
            registerId, 
            schemaRegister, 
            (body) => {
                sendForm(body,'/api/sign-in/registration-user/').then(res=>{
                    window.location.href = registerSendOn + `?email=${body.email}`;
                }).catch(error=>{
                    const inputSpanBlocks = document.querySelectorAll('.input-text_span-block');
                    inputSpanBlocks.forEach(block=> {
                        const input = block.querySelector('input');
                        input.parentElement.classList.add('error')
                    })
                    console.log("error on register");
                    console.error(error);
                })
            }
        )
    }

    const login = document.getElementById('login-form');
    const loginId = '#login-form';
    const loginSendOn = '/profile/settings/';
    if(login) {
        formValidate(
            login, 
            loginId, 
            schemaLogin, 
            (body) => {
                sendForm(body,'/api/login/').then(res=>{
                    const token = res.token
                    document.cookie = "token=" + token + ";path=/";
                    
                    const getToken = userLoggedIn();
                    const getTokenTrim = getToken.trim();
                    setCookieAuthUser(getTokenTrim);
                    removeAllFromBasket();
                    removeAllFromWishlist();
                    window.location.href = loginSendOn;
                }).catch(error=>{
                    console.log("error on login");
                    console.error(error);
                    const inputs = document.querySelectorAll('.login-section__label');
                    inputs.forEach(input=> {
                        input.classList.add('error');
                    })
                })
            }
        )
    }

    const verification = document.getElementById('verification');
    const verificationId = '#verification';
    const verificationSendOn =  '/profile/settings/';
    if(verification) {
        formValidate(
            verification, 
            verificationId, 
            schemaVerification, 
            (body) => {
                const info = {
                    ...body,
                    email: getQuery('email')
                }
                sendForm(info,'/api/sign-in/confirm-email/').then(res=>{
                    const token = res.token
                    document.cookie = "token=" + token + ";path=/";

                    const getToken = userLoggedIn();
                    const getTokenTrim = getToken.trim()
                    setCookieAuthUser(getTokenTrim);
                    removeAllFromWishlist();
                    window.location.href = verificationSendOn;
                }).catch(error=>{
                    const codeInput = document.querySelector('input[name="code"]');
                    codeInput.parentElement.classList.add('error')
                    console.log("error on confirm");
                    console.error(error);
                })
            }
        )
    }

    const recovery = document.getElementById('recovery');
    const recoveryId = '#recovery';
    const recoverySendOn =  '/authorise/change-password/';
    if(recovery) {
        formValidate(
            recovery, 
            recoveryId, 
            schemaRecovery, 
            (body) => {
                sendForm(body, '/api/sign-in/request-password-recovery/').then(res=>{
                    const dialogWindow = document.querySelector('.dialog-success');
                    const button = document.querySelector('.button_accent');
                    button.classList.add('button_accent_disabled');
                    dialogWindow.classList.add('active');
                    setTimeout(() => {
                        dialogWindow.classList.remove('active');
                    }, 5000);
                }).catch(error=>{
                    const mailInput = document.querySelector('input[name="email"]');
                    mailInput.parentElement.classList.add('error')
                    console.log("error on recovery");
                    console.error(error);
                })
                // window.location.href = recoverySendOn + `?email=${body.email}`;
            }
        )
    }

    const newPass = document.getElementById('newPass');
    const newPassId = '#newPass';
    const newPassSendOn =  '/profile/settings/';
    if(newPass) {
        formValidate(
            newPass, 
            newPassId, 
            schemaNewPass, 
            (body) => {
                const params = new URL(location.href).searchParams;
                const uid = params.get('uid');
                const info = {
                    ...body,
                    uid         
                };
                sendForm(info, '/api/sign-in/password-recovery/').then(res=>{
                    window.location.href = newPassSendOn;
                }).catch(error=>{
                    console.log("error on confirm");
                    console.error(error);
                })
            }
        )
    }

    const callBack = document.getElementById('callback');
    const callBackId = '#callback';
    if(callBack) {
        formValidate(
            callBack, 
            callBackId, 
            schemaCallBack, 
            (body) => {
                const currentProm = document.getElementById('current_promotion');
                if(currentProm) {
                    body.promotion = currentProm.getAttribute('data-id');
                }
                sendForm(body,'/api/order/callback/').then(res=>{
                    document.querySelectorAll(".modal").forEach((el)=>{
                        el.attributes['data-modal-state'].value = "close";
                    });
                    document.documentElement.style.overflow = null;
                    showDialogSuccess(callBack);
                    // window.location.href = callBackSendOn;
                }).catch(error=>{
                    callBack.querySelectorAll('input').forEach(input=>{
                        input.parentNode.classList.add('error')
                    })
                    console.log("error on callback");
                    console.error(error);
                })
            }
        )
    }


    const review = document.getElementById('review');
    const reviewId = '#review';
    if(review) {
        formValidate(
            review, 
            reviewId, 
            schemaReview,
            (body) => {
                sendForm201(body,'/api/review/')
                .then(res=>{
                    // window.location.href = reviewSendOn;
                    showDialogSuccess(review)
                }).catch(error=>{
                    console.log("error on review");
                    console.error(error);
                })
            }
        )
    }

    const order = document.getElementById('order');
    const orderId = '#order';
    const orderSendOn = '/order-success/';
    if(order) {
        const getToken = userLoggedIn();

        formValidate(
            order, 
            orderId, 
            schemaOrder, 
            (body) => {
                let products = [];
                const id = window.location.pathname.split('/')[2]
                const productCards = document.querySelectorAll('.basket-order-section__positions__image');

                const comment = document.getElementById('comment')
                productCards.forEach((card) => {
                    let productData = {};
                    const quantityVal = card.querySelector('.product__quantity').value;
                    const productId = card.querySelector('.product__id').value;
                    productData['quantity'] = quantityVal;
                    productData['product'] = productId;
                    products.push(productData);
                });
                const bodyValidated = {};
                bodyValidated['comment'] = comment.value
                for (const [key, value] of Object.entries(body)) {
                    if(value) bodyValidated[key] = value;
                    if(key == 'date_delivery' && value) bodyValidated[key] = formatDate(value);
                }
                const info = {
                    ...bodyValidated,
                    products
                };

                if(userLoggedIn()) {
                    sendFormWithToken(info,'/api/order/' + id + '/set-full-info/').then(res=>{
                        window.location.href = orderSendOn + id;
                    }).catch(error=>{
                        order.querySelectorAll('input').forEach(el=>{el.parentNode.classList.add('error')})
                        console.log("error on order");
                        console.error(error);
                    });
                } else {
                    sendForm(info,'/api/order/' + id + '/set-full-info/').then(res=>{
                        window.location.href = orderSendOn + id;
                    }).catch(error=>{
                        order.querySelectorAll('input').forEach(el=>{el.parentNode.classList.add('error')})
                        console.log("error on order");
                        console.error(error);
                    });
                }
            }
        )
    }

    const updUserInfo = document.getElementById('update-user-info');
    const updUserInfoId = '#update-user-info';
    if(updUserInfo) {
        formValidate(
            updUserInfo,
            updUserInfoId,
            schemaUpdUserInfo,
            (formData) => {
                const form = new FormData(updUserInfo);
                const comment = document.getElementById('comment');
                const file = document.getElementById('file');
                if(comment.value !== '') {
                    form.append('delivery_comment', comment.value)
                }
                if(file.files[0]) {
                    form.append('requisites', file.files[0])
                }
                const getToken = userLoggedIn();

                sendFormPatch(form, '/api/users/update-user-info/', getToken).then(res=>{
                    const saveBtn = document.querySelector('.personal-account-section__container__form__save-edit__save-btn');
                    if(saveBtn) saveBtn.classList.add('button_accent_disabled');
                    const dialogWindow = document.querySelector('.dialog-success');
                    dialogWindow.classList.add('active');
                    setTimeout(() => {
                        dialogWindow.classList.remove('active');
                    }, 5000);
                    console.log(res);
                }).catch(error=>{
                    console.log("error on update user info");
                    console.error(error);
                });
            }
        )
    }

    if(window.location.pathname.includes('/profile/settings/')) {
        const mailInput = document.getElementById('email')
        const mailPrevValue = mailInput.value;
        mailInput.addEventListener('input',() => {
            const mailNewValue = mailInput.value;
            if(mailPrevValue !== mailNewValue) {
                formValidate(
                    updUserInfo,
                    updUserInfoId,
                    schemaUpdUserInfo,
                    (body) => {

                        let mail = {};
                        mail['email'] = body.email;
                        sendFormWithToken(mail, '/api/users/update-user-email/').then(res=>{
                            const saveBtn = document.querySelector('.personal-account-section__container__form__save-edit__save-btn');
                            if(saveBtn) saveBtn.classList.add('button_accent_disabled');
                            if(res.change) {
                                const modal = document.getElementById('email-confirmation-modal');
                                modal.dataset.modalState = 'open';
                            }
                        }).catch(error=>{
                            console.log("error on update user email");
                            console.error(error);
                        });
                    }
                )
            }
        })   
    }

    const confirmNewEmail = document.getElementById('confirm-new-email-form');
    const confirmNewEmailId = '#confirm-new-email-form';
    if(confirmNewEmail) {
        formValidate(
            confirmNewEmail,
            confirmNewEmailId,
            schemaVerification,
            (body) => {
                sendFormWithToken(body, '/api/users/confirm-user-email/').then(res=>{
                    window.location.reload();
                }).catch(error=>{
                    const input = document.getElementById('code');
                    input.parentElement.classList.add('error')
                    console.log("error on confirm user email");
                    console.error(error);
                });
            }
        )
    }

} 

function formValidate(name, id, schema, callBack, formData) {
    name.onsubmit = (e) => {
        e.preventDefault();
    }
    const button = name.elements.button;

    const inputs = Object.keys(getBody(name))
        .map(el=>
            document.querySelectorAll(`*[name=${el}]`)
        )
        .map(el=>Array.from(el).find(elem=>elem.closest(id)));
    
    inputs.forEach((el)=>{
        el.oninput = (evt) => {
            inputs.forEach((e) => {
                e.parentNode.classList.remove('error');
            });

            const body = getBody(evt.target.form);
            validateParse({
                schema: schema,
                body,
            }).then(res => {
                button.classList.remove('button_accent_disabled');
                button.onclick = () => {
                    const formDataBody = new FormData(name);
                    if(callBack) formData ? callBack(formDataBody) : callBack(body);
                }
            }).catch(error => {
                const parse = JSON.parse(`[${getTextInsideSquareBrackets(error.toString())}]`);
                const nameErrorInput = parse.map(el=>el.path[0]);
                const input = inputs.filter(el=>nameErrorInput.includes(el.name));
                button.classList.add('button_accent_disabled');
                input.forEach((el) => {
                    el.parentNode.classList.add('error');
                });
                button.onclick = () => {};
            });
        }
    })
}

async function validateParse(validateInfo) {
    try {
        validateInfo.schema.parse(validateInfo.body);
        if(typeof validateInfo?.callback == 'function')validateInfo?.callback();
        return true;
    } 
    catch (error) {
        if (error instanceof z.ZodError) {
            // console.error("Validation failed:", error.errors);
            throw new Error(JSON.stringify(error.errors));
        } else {
            // console.error("Unknown error", error);
        }
    }
}

export async function sendFormWithToken(data, url) {
    const csrfToken = $('[name=csrfmiddlewaretoken]').val();
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': "application/json",
            'X-CSRFToken': csrfToken,
            'Authorization': 'Token ' + userLoggedIn(),
        },
        body: JSON.stringify(data),
    });
    if(response.ok) return response.json();
    else throw new Error(response.statusText);
};

export async function sendForm(data, url) {
    const csrfToken = $('[name=csrfmiddlewaretoken]').val();
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': "application/json",
            'X-CSRFToken': csrfToken
        },
        body: JSON.stringify(data),
    });
    if(response.ok) return response.json();
    else throw new Error(response.statusText);
};

async function sendForm201(data, url) {
    const csrfToken = $('[name=csrfmiddlewaretoken]').val();
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': "application/json",
            'X-CSRFToken': csrfToken
        },
        body: JSON.stringify(data),
    });
};

export async function sendFormPatch(data, url, tokenVal) {
    const csrfToken = $('[name=csrfmiddlewaretoken]').val();
    const response = await fetch(url, {
        method: "PATCH",
        headers: {
            'X-CSRFToken': csrfToken,
            'Authorization': 'Token ' + tokenVal,
        },
        body: data,
    });
    if(response.ok) return response;
    else throw new Error(response.statusText);
};

function getBody(form) {
    const formData = new FormData(form);
    const body = {};
    for (let [name, value] of formData.entries()) {
        body[name] = value;
    }
    return body;
};
function getQuery(query) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(query);
};

function setCookieAuthUser(tokenVal) {
    getBasketFromCookie().forEach((el) => {
        basketChangeFetch(el, 'add/', tokenVal)
    });
    getWishlistFromCookie().forEach((el) => {
        wishlistChangeFetch(el, 'add/', tokenVal)
    });
};

function formatDate(inputDate) {
    const parts = inputDate.split('.');
    if (parts.length !== 3) {
        return
    }
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
};

function showDialogSuccess(form) {
    const dialog = document.querySelector('.dialog-success');
    const dialogBtn = dialog.querySelector('.dialog-success__close-btn');
    dialog.classList.add('active');
    dialogBtn.addEventListener('click',() => {
        dialog.classList.remove('active');
    });
    setTimeout(() => {
        dialog.classList.remove('active');
    }, 5000);
    Array.from(form.elements).forEach((el) => {
        if(el.localName == 'input') el.value = '';
        if(el.localName == 'button') el.classList.add('button_accent_disabled');
        if(el.localName == 'textarea') el.value = '';
    });
};
